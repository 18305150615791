<template>
    <b-container v-if="cardRows" fluid>
        <b-row v-if="associate">
            <b-col cols="12" md="6">
                <mara-table :item="associate.client"></mara-table>
            </b-col>
            <b-col cols="12" md="6">
                <mara-table :item="associate"></mara-table>
            </b-col>
        </b-row>

        <b-row class="my-4">
            <b-col md="6">
                <b-form-group :label-cols-md="4"
                              label="Datum od:"
                              label-for="date_from">
                    <date-picker format="DD.MM.YYYY."
                                 v-model="date_from"
                                 :lang="$parent.hrv"
                                 :first-day-of-week="1"
                                 class="w-100"
                                 id="date_from">
                    </date-picker>
                </b-form-group>
            </b-col>
            <b-col md="6">
                <b-form-group :label-cols-md="4"
                              label="Datum do:"
                              label-for="date_to">
                    <date-picker format="DD.MM.YYYY."
                                 v-model="date_to"
                                 :lang="$parent.hrv"
                                 :first-day-of-week="1"
                                 class="w-100"
                                 id="date_to">
                    </date-picker>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row class="justify-content-center text-left py-2">
            <b-col cols="12" class="text-center">
                <h3 v-if="isIOS" class="m-2">Izvod otvorenih stavki {{associateType}}</h3>
                <h3 v-else class="m-2">Analitička kartica {{associateType}}</h3>
                <b-btn v-if="isIOS" @click="goToCard" class="print-hide float-right" variant="primary">Kartica</b-btn>
                <b-btn v-else @click="goToIOS" class="print-hide float-right text-uppercase" variant="primary">Izvod otvorenih stavki</b-btn>
            </b-col>
        </b-row>

        <b-row class="py-4" v-if="cardRows">
            <b-col>
                <roza-table :items="cardRows"
                            :fields="cardFields"
                            :prenosFields="prenosFields"
                            :prenosColspan="5"
                            :colIndex="1"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :actions="actions"
                            @action="action"
                            print-page>
                    <template slot="custom-header">
                        <th colspan="4"></th>
                        <th colspan="2">Promet</th>
                        <th colspan="3"></th>
                    </template>
                    <template slot="balance" slot-scope="data">
                        <template v-if="data.item.balance && data.item.balance !== '0,00'">
                        <!--<template v-if="data.nestItem[data.nestItem.length - 1] === data.item">-->
                            <template v-if="data.item.balance[0] === '-'">
                                {{data.item.balance.substring(1)}}
                            </template>
                            <template v-else>
                                {{data.item.balance}}
                            </template>
                        </template>
                        <!--</template>-->
                    </template>
                    <template slot="os" slot-scope="data">
                        <template v-if="data.item.balance">
                            <template v-if="data.item.balance[0] === '-'">
                                D
                            </template>
                            <template v-else-if="data.item.balance !== '0,00'">
                                P
                            </template>
                        </template>
                    </template>
                    <template slot="osPrenos">
                        <template v-if="cardRows.totalBalance !== '0,00'">
                            {{cardRows.totalBalance[0] === '-' ? 'D' : 'P'}}
                        </template>
                    </template>
                    <template slot="invoice_status" slot-scope="data">
                        <template v-if="data.item.invoice_status === 1">
                            Z
                        </template>
                        <template v-else>
                            O
                        </template>
                    </template>
                    <template slot="totalBalancePrenos">
                        <template v-if="cardRows.totalBalance[0] === '-'">
                            {{cardRows.totalBalance.substring(1)}}
                        </template>
                    </template>
                    <template slot="totalBalanceDonos" slot-scope="data">
                        <template v-if="cardRows.totalBalancePrev && cardRows.totalBalancePrev[0] === '-'">
                            {{cardRows.totalBalancePrev.substring(1)}}
                        </template>
                    </template>
                </roza-table>
                <b-pagination align="right"
                              @input="getRows"
                              :total-rows="cardRows.total"
                              v-model="currentPage"
                              :per-page="perPage">
                </b-pagination>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: 'AssociateCard',
        data() {
            return {
                cardRows: null,
                associate: null,
                date_from: null,
                date_to: null,
                cardFields: [
                    {
                        key: 'position',
                        label: 'Broj'
                    },
                    {
                        key: 'description',
                        label: 'Opis dokumenta'
                    },
                    {
                        key: 'invoice_number',
                        label: 'Broj dokumenta'
                    },
                    {
                        key: 'invoice_date',
                        label: 'Datum stavke',
                        date: true
                    },
                    {
                        key: 'document_date',
                        label: 'Datum dokumenta',
                        date: true
                    },
                    {
                        key: 'debit',
                        label: 'Duguje',
                        class: 'text-right'
                    },
                    {
                        key: 'credit',
                        label: 'Potražuje',
                        class: 'text-right'
                    },
                    {
                        key: 'os',
                        label: 'OS'
                    },
                    {
                        key: 'balance',
                        label: 'Saldo',
                        class: 'text-right'
                    },
                    {
                        key: 'invoice_status',
                        label: 'Status'
                    }
                ],
                prenosFields: [
                    'totalDebit',
                    'totalCredit',
                    {
                        key: 'os',
                        class: 'text-center'
                    },
                    {
                        key: 'totalBalance'
                    },
                    ''
                ],
                actions: {
                    status: {
                        name: 'Promijeni status',
                        icon: 'fa fa-sync'
                    }
                },
                currentPage: 1,
                perPage: 10
            }
        },
        computed: {
            associateType: function () {
                if (this.associate && this.associate.type == 1){
                    return 'kupca';
                } else {
                    return 'dobavljača';
                }
            },
            isIOS() {
                return this.$route.path.match('ios');
            },
        },
        methods: {
            getRows() {
                axiOsta.get(api.associates + this.$route.params.id + api.rows, {
                    params: {
                        page: this.currentPage,
                        perPage: this.perPage,
                        from: this.date_from,
                        to: this.date_to,
                        year: this.$cookie.get('ezint-year'),
                        ios: this.$route.path.match('ios') ? 1 : 0
                    }
                }).then(response => {
                    if (response.status === 200) {
                        this.cardRows = response.data;
                    }
                })
            },
            changeStatus(item) {
                axiOsta.post(api.invoiceStatus, {
                    invoice_id: item.invoice_id,
                    year: this.$cookie.get('ezint-year'),
                }).then(response => {
                    if (response.status === 200) {
                        this.getRows();
                    }
                })
            },
            action: function (action, item) {
                switch (action) {
                    case 'status':
                        this.changeStatus(item);
                        break;
                }
            },
            goToIOS() {
                this.$router.push('/associate_cards/' + this.$route.params.id + '/ios');
            },
            goToCard() {
                this.$router.push('/associate_cards/' + this.$route.params.id);
            },
        },
        created() {
            this.getRows();
            axiOsta.get(api.associates + this.$route.params.id).then(response => {
                if (response.status === 200) {
                    this.associate = response.data;
                }
            });
            eventBus.$on('year-changed', () => {
                this.getRows();
            });
        },
        watch: {
            date_from: function () {
                this.getRows();
            },
            date_to: function () {
                this.getRows();
            },
            '$route': function (to, from) {
                    this.getRows();
            }
        }
    }
</script>
